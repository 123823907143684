import React from 'react'
import useReveal from 'hooks/useReveal'

import Navigation from './Navigation'
// import LanguageSwitch from './LanguageSwitch'

import 'reveal.js/dist/reveal.css'
import 'reveal.js/dist/theme/black.css'

import './styles.css'

const Reveal = ({ children, history }) => {
    const { loading } = useReveal(history)
    return (
        <>
            {loading && <div className='loading-overlay'>Loading...</div>}
            <div className='reveal'>
                <div className='slides'>
                    {children}
                    <Navigation />
                </div>
            </div>
        </>
    )
}

export default Reveal
