import React from 'react'

function IconNext ({
    backgroundColor = '#FFFFFF'
}) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='26'
            viewBox='0 0 16 26'
        >
            <g
                fill='none'
                fillRule='evenodd'
                stroke='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1'
            >
                <g stroke={backgroundColor} strokeWidth='5' transform='translate(-1845 -137)'>
                    <g transform='translate(1829 60)'>
                        <path d='M29 80L19 90 29 100'></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IconNext
