import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import Home from 'pages/Home'
import ProductDemo from 'pages/ProductDemo'

const MainLayout = ({ children }) => {
    return (
        <div>
            {children}
        </div>
    )
}

const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />
)

const NoLayout = ({ children }) => (<>{children}</>)

const App = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    {/* <AppRoute path='/old-demonstration/:id' component={Home} layout={NoLayout} /> */}
                    <AppRoute path='/:id' component={ProductDemo} layout={NoLayout} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}

export default App
