import React from 'react'

function IconNext ({
    backgroundColor = '#FFFFFF'
}) {
    return (
        <svg
            width='16'
            height='26'
            viewBox='0 0 16 26'
        >
            <g
                fill='none'
                fillRule='evenodd'
                stroke='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1'
            >
                <g
                    stroke={backgroundColor}
                    strokeWidth='5'
                    transform='translate(-1847 -72)'>
                    <g transform='translate(1829 60)'>
                        <path
                            d='M31 15L21 25 31 35'
                            transform='matrix(-1 0 0 1 52 0)'
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IconNext
