import React from 'react'
import Slide from 'components/Slide'
import Reveal from 'components/Reveal'
import RevealJs from 'reveal.js'

import image1 from './images/1.jpg'
import image2 from './images/2.jpg'
import demo3a from './images/demo3a.jpg'
import demo3b from './images/demo3b.jpg'
import demo3c from './images/demo3c.jpg'

import styles from './styles.module.scss'

function ProductDemo (props) {
    const { id } = props.match.params
    const [presentation, setPresentation] = React.useState(null)

    React.useEffect(() => {
        (async () => {
            const response = await window.fetch(`https://${process.env.REACT_APP_API_HOST}/api/presentation/${id}.json`)

            if (!response.ok) {
                window.alert('Unable to load presentation')
            }

            const json = await response.json()
            setPresentation(json.presentation)
            console.log('response: ', json.presentation)

            RevealJs.configure({ previewLinks: false }) // make reveal.js refresh the previewlink
        })()
    }, [id])

    return (
        <Reveal history={props.history}>
            <Slide backgroundUrl={image1}>
                <a
                    className={styles.homeSiteLink}
                    target='_blank'
                    href='https://www.fundraising.shiftmarketing.io/'
                    rel='noreferrer'>&nbsp;</a>
            </Slide>
            <Slide backgroundUrl={image2}>
                <a
                    className={styles.sandboxLink}
                    target='_blank'
                    href={presentation ? presentation.livePreview.crm : '#missing'}
                    rel='noreferrer'
                >&nbsp;</a>
            </Slide>
            <Slide backgroundUrl={demo3a}/>
            <Slide backgroundUrl={demo3b}/>
            <Slide backgroundUrl={demo3c}/>
        </Reveal>
    )
}

export default ProductDemo
